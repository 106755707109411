import { fetchWithTimeout, onSignOut } from '@/common/shared'
import { format } from 'date-fns'
import { keycloakBaseUrl } from '@/settings'
import { refreshToolPositions } from '@/components/ui/ToolManager/rearrange.js'

const callEhrTokenPayload = async (kcIdpHint) => {
  const exchangeUrl = `${keycloakBaseUrl}/broker/${kcIdpHint}/token`
  const params = {
    method: 'GET',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: 'Bearer ' + window.access_token },
  }
  const externalPayloadResponse = await fetchWithTimeout(exchangeUrl, params)
  if (externalPayloadResponse.status === 200) {
    const externalPayloadResult = await externalPayloadResponse.json()
    return externalPayloadResult
  }
  console.log('Not an EHR launch, continue with standalone mode.')
  return
}

const createEpicPatient = (externalPayload, mrn) => {
  const dob = externalPayload?.dob?.replaceAll('%2F', '-')
  return {
    birthdate: dob ? format(new Date(dob), 'yyyy-MM-dd') : '',
    family: externalPayload.lname,
    gender: externalPayload.gender,
    given: externalPayload.fname,
    id: mrn,
    name: `${externalPayload.lname || '—'}, ${externalPayload.fname || '—'}`,
    isChartLaunchedPatient: true,
  }
}

export const setEhrPatientSession = async (store) => {
  try {
    const externalPayload = await callEhrTokenPayload(window.idp)
    const patient = createEpicPatient(externalPayload, window.patient_mrn)
    store.dispatch('setSessionPatient', { patient, publish: true })
    refreshToolPositions()
  } catch (e) {
    console.error(e)
    alert('Something is going wrong, force to sign out. Please contact datma.')
    onSignOut()
  }
}
