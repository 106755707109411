<template>
  <div class="auth-page-container">
    <div class="logo"></div>
    <div class="auth-container spinner">
      {{ tr('Authenticating…') }}
    </div>
  </div>
</template>

<script setup>
// Copyright (C) dātma, inc™ - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { inject, onMounted } from 'vue'
import { useStore } from 'vuex'
import auth from './auth.js'

const store = useStore()
const tr = inject('tr')
const { handleAuthFlow } = auth.setup()

onMounted(async () => {
  if (store.getters.jwtIsValid(new Date())) { return }
  handleAuthFlow()
})
</script>

<style scoped>
.auth-page-container {
  position: relative;
  background-color: var(--White);
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all linear 300ms;
}

.logo {
  position: absolute;
  top: 4px;
  left: 34px;
  width: 220px;
  height: 79px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('../../ux3/components/img/login-logo.png');
  transition: all linear 300ms;
}

.auth-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  font-size: 1.5rem;
  transition: all linear 300ms;
}
</style>
