// Copyright (C) dātma, inc™ - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { fetchWithTimeout, parseJwt } from '@/common/shared'
import { validateJwt } from './jwt'
import { keycloakBaseUrl, keycloakClientId, keycloakClientSecret, keycloakEhrLaunchIdp } from '@/settings'
import { setEhrPatientSession } from './fhir'

export function jsonToURLEncoded(element, key, list) {
  list = list || []
  if (typeof (element) === 'object') {
    for (const idx in element)
      jsonToURLEncoded(element[idx], key ? key + '[' + idx + ']' : idx, list)
  } else {
    list.push(key + '=' + encodeURIComponent(element))
  }
  return list.join('&')
}


const generateCodeChallenge = async (codeVerifier) => {
  const digest = await crypto.subtle.digest('SHA-256',
    new TextEncoder().encode(codeVerifier))

  return window.btoa(String.fromCharCode(...new Uint8Array(digest)))
    .replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
}

export default {
  setup() {
    const route = useRoute()
    const store = useStore()

    // TODO: Find secure way to make secrets available to Vue3 app!
    if (!keycloakBaseUrl) { alert('missing VUE_APP_KEYCLOAK_BASE_URL env var!'); return }
    if (!keycloakClientId) { alert('missing VUE_APP_KEYCLOAK_CLIENT_ID env var!'); return }

    const processAuthResponse = (authResponse, redirectUrl) => {
      store.dispatch('onLogin', { authResponse, redirectUrl })
    }

    const callKeycloakToken = async (data, redirectUrl) => {
      const url = `${keycloakBaseUrl}/protocol/openid-connect/token`
      try {
        const fetchParams = {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: jsonToURLEncoded(data),
        }
        const response = await fetchWithTimeout(url, fetchParams)
        const result = await response.json()
        if (!result) {
          console.log('ERROR: no result from Keycloak; ignoring login')
          return
        }
        if (!result.access_token) {
          console.log('ERROR: invalid access token, ignoring login:', result)
          return
        }
        const isValid = validateJwt(result.access_token)
        if (!isValid) {
          console.log('ERROR: invalid Keycloak JWT; ignoring login')
          return
        }
        window.session_state = result.session_state
        window.access_token = result.access_token
        window.refresh_token = result.refresh_token
        window.idp = result.idp || '' // <= idp stands for identity provider, if login from epic, the idp value (configured in keycloak) will be available
        window.patient_mrn = result.patient_mrn || ''
        window.id_token = result.id_token // <= required for redirecting back to the login screen
        const minRefreshSeconds = result.expires_in <= result.refresh_expires_in ? result.expires_in : result.refresh_expires_in

        const delayMillis = (minRefreshSeconds - 10) * 1000
        console.log(`refreshing access token in ${delayMillis / 1000} seconds...`)
        setTimeout(async () => {
          console.log('refreshing access token')
          const refreshData = {
            grant_type: 'refresh_token',
            client_id: keycloakClientId,
            client_secret: keycloakClientSecret,
            refresh_token: result.refresh_token,
          }
          await callKeycloakToken(refreshData, '')
        }, delayMillis)

        if (data.grant_type === 'authorization_code') {
          processAuthResponse(result, redirectUrl)
        } else {
          store.dispatch('saveAuthResponse', result)
        }
      } catch (e) {
        if (e.name === 'AbortError') {
          console.log('timeout error')
        } else {
          console.log('ERROR:', e)
        }
        window.location.replace(window.location.origin)
      }
    }

    const getLaunchIssParam = (redirectUrl) => {
      const params = redirectUrl.split('?')[1]
      return params.replace('iss=', 'aud=')
    }

    const getAud = (launchIssParam) => {
      if (!launchIssParam) { return '' }
      const aud = launchIssParam.split('&').find(p => p.startsWith('aud=')).split('=')[1]
      return aud
    }

    const getIdp = (aud) => {
      if (!aud) { return '' }
      try {
        const kcIdpHint = keycloakEhrLaunchIdp ? JSON.parse(keycloakEhrLaunchIdp)[decodeURIComponent(aud)] : 'epic'
        return kcIdpHint
      } catch (e) {
        console.log('VUE_APP_KEYCLOAK_EHR_LAUNCH_IDP is not a valid JSON string')
      }
      return ''
    }

    const handleAuthFlow = async () => {
      const redirectUrl = route.query.redirect_url  // the originally-requested URL

      const slashPos = location.toString().indexOf('/login')
      const redirectBaseUrlPrefix = location.toString().substring(0, slashPos + '/login'.length)
      let redirectBaseUrl = redirectBaseUrlPrefix + (redirectUrl ? `?redirect_url=${redirectUrl}` : '')
      let launchIssParam = ''
      let kcIdpHint = ''
      let aud = ''

      // before exchange code is available
      if (redirectUrl && redirectUrl.indexOf('launch') >= 0 && redirectUrl.indexOf('iss=') >= 0) {
        launchIssParam = getLaunchIssParam(redirectUrl)
        aud = getAud(launchIssParam)
        const idp = getIdp(aud)
        if (idp) { kcIdpHint = `&kc_idp_hint=${idp}` }
        redirectBaseUrl = `${redirectBaseUrlPrefix}?from_fhir=true`
      }
      // after exchange code is available
      if (route.query.from_fhir === 'true') {
        redirectBaseUrl = `${redirectBaseUrlPrefix}?from_fhir=true`
      }

      // Generates the codes required for Proof Key for Code Exchange (PKCS)
      // flow in oAuth2. For full spec see: https://datatracker.ietf.org/doc/html/rfc7636
      // Code Verifier must be between 43-128 characters.
      const codeVerifier = window.crypto.randomUUID() + window.crypto.randomUUID()
      const codeChallenge = await generateCodeChallenge(codeVerifier)

      const queryState = route.query.state
      const querySessionState = route.query.session_state
      const queryCode = route.query.code
      if (queryState && querySessionState && queryCode) {
        const keycloakState = sessionStorage.getItem('keycloakState')
        if (!keycloakState) {
          const msg = 'stored keycloak state nonce is undefined'
          alert(msg)
          throw new Error(msg)
        }
        if (queryState !== keycloakState) {
          const msg = 'query state does not match keycloak state'
          alert(msg)
          throw new Error(msg)
        }

        const retrievedCodeVerifier = sessionStorage.getItem('codeVerifier') || ''

        const data = {
          grant_type: 'authorization_code',
          client_id: keycloakClientId,
          code: queryCode,
          redirect_uri: redirectBaseUrl,
          client_secret: keycloakClientSecret,
          code_verifier: retrievedCodeVerifier,
        }

        await callKeycloakToken(data, redirectUrl)

        if (window.idp && window.patient_mrn) {
          await setEhrPatientSession(store)
        }

        const decodedJwt = parseJwt(window?.access_token)
        const profile = {
          keyCloakId: decodedJwt?.sub || '',
          firstName: decodedJwt?.given_name || '',
          lastName: decodedJwt?.family_name || '',
          email: decodedJwt?.email || '',
          displayName: decodedJwt?.displayName || '',
          timeZone: decodedJwt?.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone,
          language: decodedJwt?.language || '',
        }

        store.dispatch('saveProfile', { profile })
        return
      }
      //Store the nonce value to be used for the keycloak state parameter
      const keycloakState = window.crypto.randomUUID()
      sessionStorage.setItem('keycloakState', keycloakState)
      sessionStorage.setItem('codeVerifier', codeVerifier)

      const url = `${keycloakBaseUrl}/protocol/openid-connect/auth?response_type=code&client_id=${keycloakClientId}&redirect_uri=${encodeURIComponent(redirectBaseUrl)}&state=${keycloakState}&login=true&scope=openid&${launchIssParam}${kcIdpHint}&code_challenge=${codeChallenge}&code_challenge_method=S256` // add verifier to this url

      window.location.href = url
    }

    return {
      handleAuthFlow,
    }
  },
}
